import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { AdminNavbar } from "./navbar";
import { AdminSidebar } from "./sidebar";
import '../App.css'
import '../pages/css/create_user.css'
import { useHistory } from "react-router";
import {axiosBaseUrl} from '../service/axios'
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { Toast } from 'primereact/toast';
import { fetchAllPrivilegeCode } from "./apiCalls";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CreateUser:React.FC = ()=>{

    const [passwordIcon ,setPasswordIcon] = useState<any>(faEye); 
    const [selectedPrivilege, setSelectedPrivilege] = useState<any>(null);
    const [email,setEmail] = useState<any>("")
    let [firstname,setFirstname] = useState<any>('');
    let [lastname,setLastname] = useState<any>('');
    let [password,setPassword] = useState<any>('');
    const [isActive,setIsActive] = useState<any>(true)
    const [userType,setUserType] = useState<any>('demo')
    const [syLogin,setSyLogin] = useState<any>(false)
    const [resource,setResource] = useState<any>('')
    const history = useHistory()
    const hiddenFileInput = React.useRef<any>(null);

    const [userInfo, setuserInfo] = useState<any>({
        file:[],
        filepreview:null,
       });
       const [chipsValues, setChipsValues] = useState<any>([]);
       const [ privilegValue, setPrivilegValue] = useState<any>([]);
       const [date1, setDate1] = useState<any>("");
       const toast = useRef<any>(null);
       const [symbolsObject, setSymbolObject ] = useState<any>();
       const [text, setText] = useState<any>("");
    
       const fetch = async()=>{
        await axiosBaseUrl.post(`user/all_mappedSymbol`)
        .then((res:any)=>{
            var object = res?.data?.reduce((obj:any, item:any) => Object.assign(obj, { [item.key]: item.value }), {});
            setSymbolObject(object)

        }).catch((e)=>{
            console.log(e);
        })
       }

       useEffect(()=>{
            fetch();
       },[])

       const fetchCode = async()=>{
        let code:any=[];
        await fetchAllPrivilegeCode()
        .then((res:any)=>{
            res?.data?.map((item:any)=>{
                code.push(item.s_code)
            })
        }).catch((e)=>{
            console.log(e);
        })
        code = code?.sort((a:any, b:any)=>a.localeCompare(b));
        setChipsValues(code);
     }
    
     useEffect(()=>{
        fetchCode()
     },[])
    const handleClick = (event:any) => {
        hiddenFileInput.current.click();
      };
      const handleChange = (event:any) => {
        setuserInfo({
            ...userInfo,
            file:event.target.files[0],
            filepreview:URL.createObjectURL(event.target.files[0]),
          });
      };

    const showSuccess = () => {
        toast.current.show({severity:'success', summary: 'Success', detail:'User created successfully', life: 3000});
    }
    
    const showError = () => {
        toast.current.show({severity:'error', summary: 'Error', detail:'Something wrong!', life: 3000});
    }

    let privilege_no:any =""

    const addUser=()=>{
        privilege_no = selectedPrivilege?.length;

        const userData:any = {
            "first_name": firstname,
            "last_name": lastname,
            "email_id": email,
            "pass":password,
            "usersource": resource,
            "simultaneous_flag": syLogin,
            "user_type": userType,
            "is_active": isActive,
        }
        const formdata = new FormData()
        for ( var key in userData ) {
            formdata.append(key, userData[key]);
        }
        formdata.append('privilege', JSON.stringify(privilegValue));
        formdata.append('image',userInfo.file)
        formdata.append('expiry',moment(date1).format('YYYY-MM-DD'))
        
      
        axiosBaseUrl.post(`/user/create_user`,formdata,{
                headers: { "Content-Type": "multipart/form-data" }
            })
            .then((res:any)=>{
                showSuccess();
                setTimeout(()=>{
                    history.push('/pages/admin_panel')
                },500)
                
            }).catch((e)=>{
                showError();
            })
    }

    const handleAddPrivilege = (item:any)=>{
        setPrivilegValue((oldVal:any)=>[...oldVal, item]);
    }

    const handleDeleteChip = (item:any, index:any)=>{
        setPrivilegValue(privilegValue.filter((val:any, ind:any)=>{
            return val !== item;
        }))
    }
    const handleAddAll = ()=>{
        setPrivilegValue(chipsValues)
    }

    const handleRemoveAll = ()=>{
        setPrivilegValue([]);
    }
    const handleSearch = (e:any)=>{
        setText(e)
    }

    window.addEventListener('resize',()=>{
        let ww:any =  document.body.clientWidth;
        if(ww< 500){
            document.getElementById('names')?.classList.remove('row');
        }if(ww> 500){
            document.getElementById('names')?.classList.add('row');
        }
    })

    const showPassword = () =>{
        let x:any = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
            setPasswordIcon(faEyeSlash)
        } else {
            x.type = "password";
            setPasswordIcon(faEye)
        }
    }

    const [ rotate, setRotate] = useState<boolean>(false);
    const refreshIcon = ()=>{
        setRotate(true);
    }

    const handleEmailChange = (e:any) => {
        e.preventDefault();
        if(firstname.length!==0){
            setEmail(e.target.value);
        }
    }
   

    
    const handleNameChange = (e:any)=>{
        setFirstname(e.target.value);
        setEmail(e.target.value.concat("", "@vistaintelligence.ai").toLowerCase());
        let inputName:any = document.getElementById('firstName');
        if(inputName.value.length==0){
            setEmail('');
            setPassword('');
            setLastname('');
        }
    }
    const handlePasswordChange = (e:any)=>{
        e.preventDefault();
        if(firstname.length!==0){
            setPassword(e.target.value)
        }
    }

    return <Container fluid>
                <Toast ref={toast} />
                <div>
                    <AdminNavbar />
                </div>
                <div className="row main" >
                    <div className="col-lg-2 sideBar" >
                        <AdminSidebar/>
                    </div>
                    <div className="col-lg-10 main_section">
                        <Container  className="main_div" id="createUserDiv">
                            <Form  className="form_div" >
                                <Row >
                                    <Form.Group className="header">
                                        <h3>User Registration</h3>
                                    </Form.Group>
                                </Row>
                                <div className="form_data_section" >
                                    <div className="mb-3 " id="createUserInfo">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <div>
                                                <Form.Label id="titleName">First Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter first name" id='firstName' value={firstname} onChange = {(e:any)=>{handleNameChange(e)}}/>
                                            </div>
                                            <div style={{marginTop:"11px"}}>
                                                <Form.Label id="titleName">Last Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter last name" value={lastname} onChange = {(e:any)=>{setLastname(e.target.value)}}/>
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail">
                                        <div>
                                            <Form.Label id="titleName">Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" value={email} onChange = {(e:any)=>{handleEmailChange(e)}}/>
                                        </div>
                                        <div  style={{marginTop:"11px"}}>
                                            <Form.Label id="titleName">Password</Form.Label>
                                            <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                                <Form.Control type="password" id="password" placeholder=" Enter password" value={password} onChange = {(e:any)=>{handlePasswordChange(e) }}/>
                                                <FontAwesomeIcon icon={passwordIcon} style={{position:"absolute",top:"10px",right:"8%"}} onClick={()=>showPassword()}/>
                                            </div>
                                        </div>
                                        </Form.Group>

                                    </div>
                                    <Form.Group as={Row} className="mb-3 "  >
                                        <Form.Label column sm="2" id="titleName">
                                            Privileges
                                        </Form.Label>    
                                        <Col sm="8" lg="8" style={{width:"65% !important", position:"relative"}}>
                                            <div style={{display:"flex", flexDirection:"row", position:"relative", width:"100%"}}>
                                                <div style={{display:"flex", flexDirection:"row", position:"relative", width:"100%"}}>
                                                    <Form.Control value={text} type="text" placeholder="Search products" onChange={(e:any)=>handleSearch(e.target.value)}/>
                                                    <img src="/image/search.png" alt="addBtn" style={{width:"1.5em",height:"1.5em", cursor:"pointer", position:"absolute", right:"10px", top:"6px"}} />
                                                </div>
                                                <Button variant="secondary" style={{minWidth:"5rem", marginLeft:"0.5rem"}} onClick={handleAddAll}>Add all</Button>
                                                <Button variant="secondary" style={{minWidth:"7rem", marginLeft:"0.2rem"}} onClick={handleRemoveAll}>Remove all</Button>
                                            </div>
                                            <Col className="card" style={{width:"100%", maxHeight:"20vh",marginTop:"0.5rem", overflowY:"auto", paddingLeft:"0px", paddingRight:"0px", display:"flex",
                                                        flexDirection:"row", flexWrap:"wrap"}}>
                                                {chipsValues.filter((val:any)=>{ return val.toLowerCase().indexOf(text.toLowerCase())> -1}).map((item:any, index:any)=>{
                                                    return (<div className={`${privilegValue.includes(item)?"priChips gelatine":"priOutline"}`}>
                                                        <p id="codeItem" className="priName" style={{marginBottom: "0rem", width:"80%", marginLeft:"15%"}} >{item}</p> 
                                                        <span className="closeBtn1" style={{ width:"20%", marginTop:"0%"}}>{privilegValue.includes(item)?
                                                            <img src="/image/removeBTN.png" alt="addBtn" style={{width:"1.5em",height:"1.5em", cursor:"pointer"}} onClick={()=>{handleDeleteChip(item, index)}}/>:
                                                            <img src="/image/addBTN.png" alt="addBtn" style={{width:"1.8em",height:"1.8em", cursor:"pointer"}} onClick={()=>{handleAddPrivilege(item)}}/>
                                                        }</span>
                                                    </div>)})
                                                }
                                            </Col>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group  className="mb-3 display md-12" controlId="formPlaintextPassword" >
                                        <Form.Label column md='4' lg='3' className="type" style={{paddingLeft:"0px"}} id="titleName">
                                            Simultaneous Login
                                        </Form.Label>
                                        <Col  lg='4' className="rowType">
                                            <Col sm="1" id="inputBox"><input type="radio" value="true" name="simultaneous_flag" checked={syLogin == true} onClick={() => setSyLogin(true) }/></Col>
                                            <Col sm="1">True</Col>
                                            <Col sm="1" id="inputBox"><input type="radio" value="False" name="simultaneous_flag"  checked={syLogin == false}  onClick={() => setSyLogin(false) }/></Col>
                                            <Col sm="1">False</Col>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group  className="mb-3 display" controlId="formPlaintextPassword" >
                                        <Form.Label column lg="3" className="type" style={{paddingLeft:"0px"}} id="titleName">
                                            Active
                                        </Form.Label>
                                        <Col lg="4" className="rowType">
                                            <Col sm="1" id="inputBox"><input type="radio" value="true" name="is_active"  checked={isActive == true}  onClick={() => setIsActive(true) }/></Col>
                                            <Col sm="1">True</Col>
                                            <Col sm="1" id="inputBox"><input type="radio" value="False" name="is_active" checked={isActive == false} onClick={() => setIsActive(false) }/></Col>
                                            <Col sm="1">False</Col>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group  className="mb-3 display" >
                                        <Form.Label column lg="3" className="type" style={{paddingLeft:"0px"}} id="titleName">
                                            User Type
                                        </Form.Label>
                                        <Col sm="10" md="10" lg="9" className="rowType">
                                            <Col sm="1"  id="inputBox"><input type="radio" name="user_type" value="demo" checked={userType === "demo"} onClick={() => setUserType("demo") }/></Col>
                                            <Col sm="1">Demo</Col>
                                            <Col sm="1"  id="inputBox"><input type="radio" value="full" name="user_type" checked={userType === "full"} onClick={() => setUserType("full")}/></Col>
                                            <Col sm="3">Full version</Col>
                                            <Col sm="1"  id="inputBox"><input type="radio" value="Adm" name="user_type" checked={userType === "Adm"} onClick={() => setUserType("Adm")}/></Col>
                                            <Col sm="1">Admin</Col>
                                            <Col sm="5"></Col>
                                            
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword" >
                                        <Form.Label column sm="2" id="titleName">
                                            User source
                                        </Form.Label>
                                        <Col sm="10">
                                        <Form.Control type="text" placeholder="Enter source" onChange = {(e)=>{setResource(e.target.value)}}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword" >
                                        <Form.Label column sm="2" id="titleName">
                                            Expiry 
                                        </Form.Label>
                                        <Col sm="4">
                                            <Calendar style={{width: "100%"}} id="basic" value={date1} onChange={(e) => setDate1(e.value)} showIcon placeholder="Select date" />
                                        </Col>
                                        <Col sm="6"></Col>
                                    </Form.Group><br/>
                                    <Button variant="primary" type="button" style={{width:"40%"}} className="form_submit_button" onClick={addUser}>
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </Container>
                    </div>
                </div>
            </Container>
}


