import React, { useEffect, useRef, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { AdminNavbar } from "./navbar";
import { Toast } from 'primereact/toast';
import { AdminSidebar } from "./sidebar";
import { getAllSymbol, getInsertLotsize, getLot, getLotUpdate } from "./apiCalls";


export const Lot:React.FC = ()=>{
    
    const toast = useRef<any>(null);

    const [allSymbol, setAllSymbol] = useState<any>([])
    const [lot, setLot] = useState<any>([])
    const [boolean, setBoolean] = useState<any>(false);
    const [submitData, setSubmitData] = useState<any>([]);

    const [addFormData, setAddFormData] = useState({
        s_symbol: null,
        n_lot_size: null,
        dt_start_date: null,
        dt_end_date: null,
        s_month: null,
        s_year: null,
    })

    const [editData, setEditData] = useState<any>({
        s_symbol: null,
        n_lot_size: null,
        dt_start_date: null,
        dt_end_date: null,
        s_month: null,
        s_year: null,
    })


    useEffect(() => {
        getAllSymbol().then((res:any) => {
            setAllSymbol(res)
        })
    }, [])

    useEffect(() => {
        getLot().then((res:any) => {
            setLot(res)
        })
    }, [submitData])

    const handleEditFormChange = (item: any) => {
        if(item.n_lot_size==null){
            item.n_lot_size = 0;
        }
        setEditData(item)
        setBoolean(true)
        document.getElementById("investmentCardInput")?.scrollIntoView();
    }


    const handleAddFormChange = (event: any) => {
        event.preventDefault();
        const fieldName: any = event.target.getAttribute("name");
        const fieldValue: any = event.target.value;
        const newFormData: any = { ...addFormData };
        newFormData[fieldName] = fieldValue;

        setAddFormData(newFormData);
    }


    const handleInsertData = async (e: any) => {
        setSubmitData([addFormData])
        if (addFormData?.s_symbol == null || addFormData?.n_lot_size == null || addFormData?.dt_start_date == null || addFormData?.dt_end_date == null || addFormData?.s_month == null || addFormData?.s_year == null) {
            alert("Please enter data in all the fields")
        }
        else {
            let insert_response: any = await getInsertLotsize([addFormData])
            if (insert_response == false) {
                alert("Data is already exist.")
            }
            else {
                alert("Data Inserted Successfully")
                window.location.reload();

            }
        }
    }

    const handleUpdateData = (id: any) => {
        if (id == editData?.n_id) {
            getLotUpdate(editData)
            alert("Data Updated Successfully")
            window.location.reload()
        }
    }

    const handleBoolean = (e: any) => {

        setBoolean(false)
    }

    
    return(
        <Container fluid>
            <Toast ref={toast}/>
            <div >
                <AdminNavbar/>
            </div>
            <div className="row main" >
                <div className="col-2 sideBar" >
                    <AdminSidebar/>
                </div>
                <div className="col-10"  id="investment_div">
                    <div className="investment_div" >
                        <Card  className="investment_cardTable" >
                            <div className="outer-div" style={{ overflowX: "scroll", overflowY: "scroll" }}>
                                <table className="table">
                                    <thead className="thead-dark" style={{position:"sticky", top: "0"}}>
                                        <tr>
                                            <th>Edit</th>
                                            <th>Symbol</th>
                                            <th>Lot Size</th>
                                            <th>Contract Start Date</th>
                                            <th>Contract End Date</th>
                                            <th>Month</th>
                                            <th>Year</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            lot?.map((item: any) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    onClick={(e:any) => {
                                                                        handleEditFormChange(item);
                                                                    }}
                                                                >
                                                                    Edit
                                                                </button>
                                                            </td>
                                                            <td>{item.s_symbol}</td>
                                                            <td>{item.n_lot_size===null ? 0 : item.n_lot_size}</td>
                                                            <td>{item.dt_start_date}</td>
                                                            <td>{item.dt_end_date}</td>
                                                            <td>{item.s_month}</td>
                                                            <td>{item.s_year}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Card>

                        <Card className="investment_cardInput" id="investmentCardInput">
                            {boolean == false ?
                                <div className="investment_form">
                                    <form className="form">

                                        <div className="row" id="invest_form">
                                            <div className="col-3 investOptions">
                                                <label>Symbol</label>
                                                <select name='s_symbol' onChange={handleAddFormChange} className="custom-select" id="inputGroupSelect01">
                                                    <option selected>Choose Symbol...</option>
                                                    {   allSymbol.map((item: any) => {
                                                            return (
                                                                <option value={item?.s_symbol}>{item?.s_symbol}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-3 investOptions">
                                                <label htmlFor="exampleInputPassword1">Lot Size</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                    name="n_lot_size"
                                                    onChange={handleAddFormChange}
                                                />
                                            </div>
                                            <div className="col-3 investOptions">
                                                <label htmlFor="exampleInputPassword1">Contract Start Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    required
                                                    placeholder=""
                                                    name="dt_start_date"
                                                    onChange={handleAddFormChange}
                                                />
                                            </div>
                                            <div className="col-3 investOptions">
                                                <label htmlFor="exampleInputPassword1">Contract End Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    required
                                                    placeholder=""
                                                    name="dt_end_date"
                                                    onChange={handleAddFormChange}
                                                />
                                            </div>
                                            <div className="row" style={{marginTop:"2rem"}} id="investDates">
                                                <div className="col-3 investOptions" >
                                                    <label htmlFor="exampleInputPassword1">Month</label>
                                                    <select name='s_month' onChange={handleAddFormChange} className="custom-select" id="inputGroupSelect01">
                                                        <option selected>Choose Month...</option>
                                                        <option value="January">January</option>
                                                        <option value="February">February</option>
                                                        <option value="March">March</option>
                                                        <option value="April">April</option>
                                                        <option value="May">May</option>
                                                        <option value="June">June</option>
                                                        <option value="July">July</option>
                                                        <option value="August">August</option>
                                                        <option value="September">September</option>
                                                        <option value="October">October</option>
                                                        <option value="November">November</option>
                                                        <option value="December">December</option>
                                                    </select>
                                                </div>
                                                <div className="col-3 investOptions" >
                                                    <label htmlFor="exampleInputPassword1">Year (Financial Year)</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        name="s_year"
                                                        onChange={handleAddFormChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="row">
                                                <div className="col button" style={{ marginTop:"2rem"}}>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        onClick={(e: any) => { handleInsertData(e) }}
                                                    >
                                                        ADD DATA
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                :
                                <>
                                    <div>
                                        <div className="button-newdata" style={{marginTop:"0.5rem", marginLeft:"0.5rem"}}>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e: any) => { handleBoolean(e) }}
                                            >
                                                ADD NEW DATA
                                            </button>
                                        </div>
                                    </div>
                                    <div className="investment_form">
                                        <form className="form">
                                            <div className="row" id="invest_form">
                                                <div className="col-3 investOptions">
                                                    <label htmlFor="exampleInputPassword1">Symbol</label>
                                                    <input disabled
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        name="s_symbol"
                                                        onChange={(e) =>
                                                            setEditData({ ...editData, s_symbol: e.target.value })
                                                        }
                                                        value={editData.s_symbol}
                                                    />
                                                </div>
                                                <div className="col-3 investOptions">
                                                    <label htmlFor="exampleInputPassword1">
                                                        Lot Size
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        required
                                                        name="n_lot_size"
                                                        onChange={(e) =>
                                                            setEditData({
                                                                ...editData,
                                                                n_lot_size: e.target.value,
                                                            })
                                                        }
                                                        value={editData.n_lot_size}
                                                    />
                                                </div>
                                                <div className="col-3 investOptions">
                                                    <label htmlFor="exampleInputPassword1">
                                                        Contract Start Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        required
                                                        name="dt_start_date"
                                                        onChange={(e) =>
                                                            setEditData({
                                                                ...editData,
                                                                dt_start_date: e.target.value,
                                                            })
                                                        }
                                                        value={editData.dt_start_date}
                                                    />
                                                </div>
                                                <div className="col-3 investOptions">
                                                    <label htmlFor="exampleInputPassword1">
                                                        Contract End Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        required
                                                        name="dt_end_date"
                                                        onChange={(e) =>
                                                            setEditData({
                                                                ...editData,
                                                                dt_end_date: e.target.value,
                                                            })
                                                        }
                                                        value={editData.dt_end_date}
                                                    />
                                                </div>
                                                <div className="col-3 investOptions" >
                                                    <label htmlFor="exampleInputPassword1">Month</label>
                                                    <select name='s_month' value={editData.s_month} onChange={(e) => { setEditData({ ...editData, s_month: e.target.value }) }} className="custom-select" id="inputGroupSelect01">
                                                        <option value="January">January</option>
                                                        <option value="February">February</option>
                                                        <option value="March">March</option>
                                                        <option value="April">April</option>
                                                        <option value="May">May</option>
                                                        <option value="June">June</option>
                                                        <option value="July">July</option>
                                                        <option value="August">August</option>
                                                        <option value="September">September</option>
                                                        <option value="October">October</option>
                                                        <option value="November">November</option>
                                                        <option value="December">December</option>
                                                    </select>
                                                </div>
                                                <div className="col-3 investOptions" style={{marginTop:"0.2rem"}}>
                                                    <label htmlFor="exampleInputPassword1">
                                                        Year (Financial Year)
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        required
                                                        name="s_year"
                                                        onChange={(e) =>
                                                            setEditData({
                                                                ...editData,
                                                                s_year: e.target.value,
                                                            })
                                                        }
                                                        value={editData.s_year}
                                                    />
                                                </div>


                                            </div>
                                            <div className="row">
                                                <div className="col button" style={{marginTop:"2rem"}}>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        onClick={(e) => {
                                                            handleUpdateData(editData.n_id);
                                                        }}
                                                    >
                                                        SUBMIT
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </>
                            }
                        </Card>
                    </div>
                </div>
            </div>
        </Container>
    )
}