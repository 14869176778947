import axios from "axios";
import React, { useRef, useState } from "react";
import { Button, CloseButton, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
// import { Col, Form, Row } from "react-bootstrap";
import '../pages/css/login.css'
import { axiosBaseUrl } from "../service/axios";
import { Password } from 'primereact/password';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Toast } from 'primereact/toast';


export const LoginProtal:React.FC = () =>{

    const toast = useRef<any>(null);
    const [username,setUsername] = useState<any>()
    const [password,setPassword] = useState<any>()
    const [userInfo, setuserInfo] = useState<any>({
        file:[],
        filepreview:null,
       });
    
    const [passwordIcon ,setPasswordIcon] = useState<any>(faEye); 
    const [newPasswordIcon ,setNewPasswordIcon] = useState<any>(faEye);
    const [confirmPasswordIcon ,setConfirmPasswordIcon] = useState<any>(faEye);
    const[showEditPasswordPage, setShowEditPasswordPage] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [newPassword, setNewPassword] = useState<any>();
    const [curEmail, setCurEmail] = useState<any>();
    const [confirmPassword, setConfirmPassword] = useState<any>();
    const [oldPassword, setOldPassword] = useState<any>("");
    const [showChangePasswordInput,setShowChangePasswordInput] = useState<boolean>(false)

    
    const showSuccess = (text:any) => {
        toast.current.show({severity:'success', summary: 'Success', detail:`${text}`, life: 3000});
    }
    const showError = (text:any) => {
        toast.current.show({severity:'error', summary: 'Error', detail:`${text}`, life: 3000});
    }

    const handleUser=(item:any)=>{
        setUsername(item)
    }
    const handlePassword=(item:any)=>{
        setPassword(item)
        
    }
    const handleCurEmail = (item:any)=>{
        setCurEmail(item)
    }
    const handleOldPassword = (item:any)=>{
        setOldPassword(item)
    }
    const handleNewPassword = (item:any)=>{
        setNewPassword(item)
    }
    const handleConfirmPassword = (item:any)=>{
        setConfirmPassword(item)
    }
    
    const loginSubmit = () =>{
        axiosBaseUrl.get(`/user/admin_login?email_id=${username}&pass=${password}`)
        .then((res:any)=>{
            if(res.data.status === "success") {
                showSuccess('Logged in successfully');
                setPasswordIcon(faEye)
                sessionStorage.setItem("token", res.data.token);
                sessionStorage.setItem("username", username);
                setTimeout(()=>{
                    window.location.href = "/pages/admin_panel"
                },500)
            }else{
                showError('Wrong password');
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
    const showPassword = () =>{
        let x:any = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
            setPasswordIcon(faEyeSlash)
        } else {
            x.type = "password";
            setPasswordIcon(faEye)
        }
    }
    const showNewPassword = () =>{
        let x:any = document.getElementById("newpassword");
        if (x.type === "password") {
            x.type = "text";
            setNewPasswordIcon(faEyeSlash)
        } else {
            x.type = "password";
            setNewPasswordIcon(faEye)
        }
    }
    const showConfirmPassword = () =>{
        let x:any = document.getElementById("conpassword");
        if (x.type === "password") {
            x.type = "text";
            setConfirmPasswordIcon(faEyeSlash)
        } else {
            x.type = "password";
            setConfirmPasswordIcon(faEye)
        }
    }
    const handleMatchPassword = ()=>{
        axiosBaseUrl.post(`/user/match_password`,{pass:oldPassword, email: curEmail})
        .then((res:any)=>{
            if(res?.data?.status === "success") {
                setOldPassword("");
                showSuccess('Now, you can change your password');
                setDisabled(false);
                setShowChangePasswordInput(true);
            }else{
                showError('Incorrect password');
                setDisabled(true);
            }
        }).catch((e)=>{
            console.log(e);
        })
    }

    const handleResetPassword = async()=>{
        await axiosBaseUrl.post(`user/reset_password`,{newPass: newPassword, email:curEmail})
        .then((res:any)=>{
            if(res){
                setNewPassword("")
                setConfirmPassword("")
                showSuccess('Password updated successfully');
                setShowEditPasswordPage(false)
                setShowChangePasswordInput(false)
            }else{
                showError('Error try again');
                // setShowChangePasswordInput(false)
                // setShowEditPasswordPage(true)
            }
        }).catch((e)=>console.log(e))
    }

    const handleCancel = ()=>{
        setShowChangePasswordInput(false)
        setShowEditPasswordPage(false)
    }

    return <div className="main_body" >
        <Toast ref={toast} />
        {/* <div className="col-lg-4"></div> */}
                <div className="col-lg-4 main_div_login" >
                    {!showEditPasswordPage ?
                        <Form style={{paddingTop:"2rem"}}>
                            <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
                                <h3 className="heading">Admin Login</h3>
                            </Form.Group>
                            <Form.Group className="mb-3 userloginForm" controlId="formPlaintextEmail">
                                <Form.Label column sm="2" className="userText">
                                    Username
                                </Form.Label>
                                <Col sm="8" style={{width:"65% !important"}}>
                                    <Form.Control type="text" placeholder="Enter Username" className="userInput" onChange={(e)=>handleUser(e.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group className="mb-3 userloginForm" controlId="formPlaintextPassword">
                                <Form.Label column sm="2" className="userText">
                                    Password
                                </Form.Label>
                                <Col sm="8" style={{width:"65% !important", position:"relative"}}>
                                    <Form.Control id="password" type="password" placeholder="Enter Password" className="userInput" onChange={(e)=>handlePassword(e.target.value)}/>
                                    <FontAwesomeIcon icon={passwordIcon} className="password_icon" onClick={()=>showPassword()}/>
                                </Col>
                            </Form.Group>
                            <Form.Group style={{marginTop:"2rem"}} controlId="formPlaintextPassword">
                                <div className="loginBTN">
                                    <Button className="btn btn-secondary" type="button" onClick={loginSubmit} style={{width: "100%", fontSize: "20px", fontWeight:400}}>Login</Button>
                                    <a type="button" onClick={()=>setShowEditPasswordPage(true)} style={{width: "200px", marginTop:"0.5rem", color:"#06067a",paddingLeft:"5rem"}}>Reset Password</a>
                                </div >
                            </Form.Group>
                        </Form>
                        :
                        <Form  style={{paddingTop:"2rem"}}>
                            <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
                                <h3 className="heading">Admin Login</h3>
                            </Form.Group>
                            <div className="resetDiv">
                                <Form.Group as={Col} className="mt-3 w-100" style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}} controlId="formPlaintextPassword">
                                    <Form.Label style={{float:"left"}} className="allText">
                                        Enter current email
                                    </Form.Label>
                                    <Form.Control type="text" value={curEmail} placeholder="Enter email" style={{width:"100%"}}  className="userInput" onChange={(e)=>handleCurEmail(e.target.value)}/>
                                </Form.Group>
                                {!showChangePasswordInput? 
                                    <Form.Group as={Col} className="mt-4 w-100" style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}} controlId="formPlaintextPassword">
                                        <Form.Label style={{float:"left"}} className="allText">
                                            Enter current password
                                        </Form.Label>
                                        <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                                            <Form.Control id="password" type="password" value={oldPassword} placeholder="Enter Password" style={{width:"100%"}}  className="userInput" onChange={(e)=>handleOldPassword(e.target.value)}/>
                                            <FontAwesomeIcon icon={passwordIcon} style={{position:"absolute",top:"10px",right:"8%"}} onClick={()=>showPassword()}/>
                                        </div>
                                        <div className="continueBTN">
                                            <Button type="button" className="btn btn-success" onClick={handleMatchPassword} style={{width: "50%",  fontSize: "18px", fontWeight:400, marginRight:"1rem", height:"2.5rem"}}>Continue</Button>
                                            <Button type="button" className="btn btn-danger" onClick={handleCancel} style={{width: "50%",  fontSize: "18px", fontWeight:400, height:"2.5rem", backgroundColor:"null"}}>Cancel</Button>
                                        </div>
                                    </Form.Group>
                                    :<>
                                    <Form.Group as={Col} className="mt-2 w-100" controlId="formPlaintextPassword">
                                    <Form.Label style={{float:"left"}} className="allText">
                                        Enter new password
                                    </Form.Label>
                                    <div style={{width:"100% !important", position:"relative"}}>
                                        <Form.Control id="newpassword" disabled={disabled} value={newPassword} type="password" placeholder="Password" style={{width:"100%"}} className="userInput" onChange={(e)=>handleNewPassword(e.target.value)}/>
                                        <FontAwesomeIcon icon={newPasswordIcon} style={{position:"absolute",bottom:"15%",right:"5%"}} onClick={()=>showNewPassword()}/>
                                    </div>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mt-2 w-100" controlId="formPlaintextPassword">
                                        <Form.Label style={{float:"left"}} className="allText">
                                            Confirm password
                                        </Form.Label>
                                        <div style={{width:"100% !important", position:"relative"}}>
                                            <Form.Control id="conpassword" disabled={disabled} value={confirmPassword} type="password" placeholder="Re-enter password" style={{width:"100%"}} className="userInput" onChange={(e)=>handleConfirmPassword(e.target.value)}/>
                                            <FontAwesomeIcon icon={confirmPasswordIcon} style={{position:"absolute",bottom:"15%",right:"5%"}} onClick={()=>showConfirmPassword()}/>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mt-4 w-100" controlId="formPlaintextPassword">
                                        <Button type="button" className="btn btn-secondary" disabled={disabled} onClick={handleResetPassword} style={{width:"100%", fontSize:"18px",fontWeight:400, marginRight:"2rem "}}>Change password</Button>
                                    </Form.Group>
                                    </>
                                }
                            </div>
                        </Form>
                    }
                </div>
    </div>
}