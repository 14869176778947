import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form,Row } from "react-bootstrap";
import { AdminNavbar } from "./navbar";
import { AdminSidebar } from "./sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { MultiSelect } from 'primereact/multiselect';
import '../App.css'
import '../pages/css/admin.css'
import {axiosBaseUrl} from '../service/axios'
import { Dropdown } from 'primereact/dropdown';
import CloseButton from 'react-bootstrap/CloseButton';
import { Toast } from 'primereact/toast';
import Modal from 'react-bootstrap/Modal';
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { addAllPrivileges, addPrivilegeToUser, deleteUserPrivilege, fetchAllPrivilegeCode, getUserAllPrivilege } from "./apiCalls";



function MyVerticallyCenteredModal(props:any) {
    const selectedPrivilegeTemplate = (option: { name: string, code: string }, props: { placeholder: string }) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }
        return (
            <span>
                {props.placeholder}
            </span>
        );
    }
    const privilegeOptionTemplate = (option: any) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    }
    
    let val:any = {"value":{"code": 'All'}}

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body >
            {props.showPassChange?
            <div>
                <p style={{fontSize: "medium", fontWeight: 600, color:"#4e5052"}}>Are you sure want to change password ?</p>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center", width:"100%", marginTop:"1rem"}}>
                    <button type="button" className="btn btn-secondary " style={{width:"50%", marginRight:"2rem"}} onClick={()=>{props.setShowSaveBtn(true)
                    props.setModalShow(false)}}>Yes</button>
                    <button type="button" className="btn btn-secondary " style={{width:"50%"}} onClick={()=>{props.setModalShow(false)
                    props.setShowPassChange(false)}}>No</button>
                </div>
            </div>:
            <div >
                <p style={{fontSize: "medium", fontWeight: 600, color:"#4e5052"}}>Are you sure ?</p>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center", width:"100%", marginTop:"1rem"}}>
                    {props.remove?<button type="button" className="btn btn-secondary " style={{width:"50%", marginRight:"2rem"}} onClick={()=>{props.onRemoveAllPrivilege()
                    props.setModalShow(false)
                    props.setRemove(false)}}>Yes</button>:<button type="button" className="btn btn-secondary " style={{width:"50%", marginRight:"2rem"}} onClick={()=>{props.onAddAllPrivileges()
                    props.setModalShow(false)}}>Yes</button>}
                    <button type="button" className="btn btn-secondary " style={{width:"50%"}} onClick={()=>{props.setModalShow(false)
                    props.setShowPassChange(false)}}>No</button>
                </div>
            </div>
            }
        </Modal.Body>
      </Modal>
    );
  }
let previlegeArr:any = [];

export const AdminPanel:React.FC = () =>{

    const [disableInput,setDisableInput] = useState<any>(false);
    const [allUserData,setAllUserData] = useState<any>()
    const [email,setEmail] = useState<any>("")
    let [firstname,setFirstname] = useState<any>();
    let [lastname,setLastname] = useState<any>();
    const [pass, setPass] = useState<any>("")
    const [isActive,setIsActive] = useState<any>()
    const [userType,setUserType] = useState<any>()
    const [resource,setResource] = useState<any>()
    const [userData,setUserData] = useState<any>()
    const [expiry, setExpiry] = useState<any>("")
    const [id,setId] = useState<any>()
    const toast = useRef<any>(null);
    const hiddenFileInput = React.useRef<any>(null);
    const [userInfo, setuserInfo] = useState<any>({
        file:[],
        filepreview:null,
       });
    const [userPrivilegeArr, setUserPrivilegeArr] = useState<any>([]);
    const [showSaveBtn, setShowSaveBtn] = useState<boolean>(false);
    const [showSubmitBtn, setShowSubmitBtn] = useState<boolean>(false);
    const [showAddBtn ,setShowAddBtn] = useState<boolean>(false)
    const [changeSymbol, setChangeSymbol] = useState<any>("");
    const [modalShow, setModalShow] = useState<any>(false);
    const [getUserPivilege, setGetUserPrivilege] = useState<boolean>(false);
    const [selectedUserType, setUserTypeChange] = useState<any>("Active users")
    const [privilegeCode, setPrivilegeCode] = useState<any>([]);
    const [syLogin, setSyLogin] = useState<any>();
    const [showPassChange, setShowPassChange] = useState<any>(false);
    const [newAddedPrivileges,setNewAddPrivileges] = useState<any>([]);
    const [privilegeToDelete, setPrivilegeToDelete] = useState<any>([]);
    const [text, setText] = useState<any>("");
    const [remove, setRemove] = useState<boolean>(false)
    const [userSearchText, setUserSearchText] = useState<any>("")
    const handleUserSearch = (e:any)=>{
        setUserSearchText(e)
    }

    const showSuccess = (val:any) => {
        toast.current.show({severity:'success', summary: 'Success', detail:`${val}`, life: 3000});
    }
    const showError = (val:any) => {
        toast.current.show({severity:'error', summary: 'Error', detail:`${val}`, life: 3000});
    }

    useEffect(()=>{
       axiosBaseUrl.post(`/user/all_user`,{"userType":selectedUserType})
        .then((res:any)=>{
       //    let userList = res.data.length
            setAllUserData(res.data) 
        })
    },[isActive,selectedUserType])

 useEffect(()=>{
    axiosBaseUrl.get(`/user/user_details?email_id=${email}`)
    .then((res:any)=>{
        setUserData(res.data);
       if(res.data.length>0)
       {
         setuserInfo(null)
        setFirstname(res.data[0].first_name)
        setLastname(res.data[0].last_name)
        setEmail(res.data[0].email_id)
        setUserType(res.data[0].user_type)
        setId(res.data[0].id)
        setIsActive(res.data[0].is_active)
        setResource(res.data[0].usersource)
        setExpiry(moment(res.data[0].expiry).format('MM/DD/YYYY'))
        setPass("")
        setSyLogin(res.data[0].simultaneous_flag)
       }
    })
 },[email])


 const fetch = async()=>{
    let code:any=[];
    await fetchAllPrivilegeCode()
    .then((res:any)=>{
        res?.data?.map((item:any)=>{
            code.push(item.s_code)
        })
    }).catch((e)=>{
        console.log(e);
    })
    setPrivilegeCode(code)
 }

 useEffect(()=>{
    fetch()
 },[])

const fetchUserAllPrivilege = async(email:any)=>{
    let preCode:any=[];
    await getUserAllPrivilege(email)
    .then((res:any)=>{
        res?.data?.map((item:any)=>preCode.push(item.s_code))
    })
    setUserPrivilegeArr(preCode)

    let filteredPrivilege:any = privilegeCode.filter((item:any)=>{
        return preCode.includes(item)
    })

    let notIncludesPrivileges:any = privilegeCode.filter((item:any)=>{
        return !preCode.includes(item)
    })
    filteredPrivilege = filteredPrivilege?.sort((a:any, b:any)=>a.localeCompare(b));
    notIncludesPrivileges = notIncludesPrivileges?.sort((a:any, b:any)=>a.localeCompare(b));

    setPrivilegeCode([...filteredPrivilege,...notIncludesPrivileges])
    setModalShow(false);
}

    useEffect(()=>{
        if(email || getUserPivilege){
            fetchUserAllPrivilege(email);
        }
    },[email,getUserPivilege])

    const handleDeletePrivilege = async(val:any)=>{
        setUserPrivilegeArr(userPrivilegeArr.filter((item:any, index:any)=>{
            return val !== item;
        }))
        setPrivilegeToDelete((oldPrev:any)=>[...oldPrev, val])
    }

    const handleAddPrivilege = async(value:any)=>{
        setUserPrivilegeArr((prev:any)=>[...prev,value]);
        setNewAddPrivileges((prev:any)=>[...prev, value]);
    }

 const onAddAllPrivileges = async()=>{
    let arr:any=[];
    privilegeCode.map((item:any)=>{
        arr.push(item)
    })
    setNewAddPrivileges(arr)
    setUserPrivilegeArr(arr)
 }

 const onRemoveAllPrivilege = async()=>{
    setNewAddPrivileges([])
    setUserPrivilegeArr([])
    // setPrivilegeCode([])
 }

 const handleSearch = (e:any)=>{
    setText(e)
 }
    
const userDetails = (item:any) =>{
    setEmail(item);
    setDisableInput(true);
    setDisableInput(true);
    setShowSubmitBtn(false);
    setShowSaveBtn(false);
    setDisableInput(false);
    setShowSubmitBtn(true);
    document.getElementById('userDetails')?.scrollIntoView();
 }

//  const changeData = (()=>{
//     setDisableInput(false);
//     setShowSubmitBtn(true);
//  })

 const cancelUpdate =()=>{
    setEmail("");
    setText("");
    setFirstname("");
    setLastname("");
    setUserType("");
    setIsActive("");
    setSyLogin("");
    setExpiry("");
    setPass('');
    setShowPassChange(false);
    setDisableInput(true);
    setShowSubmitBtn(false);
    setShowSaveBtn(false);
    setNewAddPrivileges([]);
    setUserPrivilegeArr([]);
    document.getElementById('adminMain')?.scrollIntoView();
 }
// console.log("newAddedPrivileges-->", newAddedPrivileges)
 const updateForm = async() =>{
    setDisableInput(false);
    const updateData:any = {
        "id":id,
        "email_id":email,
        "user_type":userType,
        "is_active":isActive,
        "usersource":resource,
        "expiry":moment(expiry).format('YYYY-MM-DD'),
        "privilege":newAddedPrivileges.length == 0 ? userPrivilegeArr :newAddedPrivileges,
        "privilegeToDelete":privilegeToDelete,
        "pass":pass,
        "simultaneous_flag":syLogin,
        // "removeAll":newAddedPrivileges.length>0?false:true,
    }
     await axiosBaseUrl.post(`/user/update_user`,updateData)
     .then((res:any)=>{
        showSuccess("Submitted successfully");
     })
     setShowSubmitBtn(false);
     setShowSaveBtn(false)
     setTimeout(()=>{
        // window.location.reload();
        setEmail("");
        setText("");
        setFirstname("");
        setLastname("");
        setUserType("");
        setIsActive("");
        setSyLogin("");
        setExpiry("");
        setPass('');
        setShowPassChange(false);
        setDisableInput(true);
        setShowSubmitBtn(false);
        setShowSaveBtn(false);
        setNewAddPrivileges([]);
        setUserPrivilegeArr([]);
        document.getElementById('adminMain')?.scrollIntoView(); 
     },500)

     document.getElementById('adminMain')?.scrollIntoView();
 }

 const handleClick = (event:any) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event:any) => {
    setuserInfo({
        ...userInfo,
        file:event.target.files[0],
        filepreview:URL.createObjectURL(event.target.files[0]),
      });
  };


    
    const user:any = [{name:"All users", value:"All users"},{name:"Active users", value:"Active users"},{name:"Inactive users", value:"Inactive users"}]
    const onUserTypeChange = (e: { value: any}) => {
        setUserTypeChange(e.value);
    }

    return <Container fluid>
            <Toast ref={toast}/>
            <div >
                <AdminNavbar/>
            </div>
            <div className="row main" id='adminMain'>
                <div className="col-2 sideBar" >
                    <AdminSidebar/>
                </div>
                <div className="col-4 card_section1" id='userDiv'>
                    <div className="admin_div">
                        <Card className="admin_card" >
                        <Card.Body>
                            <Card.Title style={{display:"flex"}}><span style={{marginBottom:"5px", marginTop:"10px"}}>Users : </span> 
                                <Dropdown value={selectedUserType} options={user} onChange={onUserTypeChange} optionLabel="name" style={{marginLeft:"10px", backgroundColor: "#fafafac7"}}/>
                            </Card.Title>
                            
                            <Card.Text>
                                <div className="row">
                                    <div className="card_header" >
                                        {/* <button className="btn btn-secondary" style={{}} type="button" onClick={()=>setModalShow(true)}>Add privileges to all</button> */}
                                        <h6>Total User : {allUserData?.length}</h6>
                                    </div>
                                    <div className="row">
                                        <div style={{display:"flex", flexDirection:"row", position:"relative", width:"100%", padding:"0rem 0rem 1rem 1rem"}}>
                                            <Form.Control type={userSearchText} placeholder="Search users" onChange={(e:any)=>handleUserSearch(e.target.value)} />
                                            <img src="/image/search.png" alt="addBtn" style={{width:"1.5em",height:"1.5em", cursor:"pointer", position:"absolute", right:"40px", top:"8px"}} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row card_body">
                                    <div className=" user_data_section" >
                                        {allUserData?.filter((val:any)=>{return (val.email_id).toLowerCase().indexOf(userSearchText.toLowerCase())>-1}).map((item:any,index:any)=>{
                                        return <div  className={`user_div ${item?.email_id === email? "selected": ""}`} key={index}>
                                                    <div className="col-lg-1 user_div_section_column userNumber" style={{textAlign:"center", paddingRight:"0px", paddingLeft:"0px"}}>{index+1.} </div>
                                                    {/* <div className="col-lg-1 user_div_section_column" >
                                                            <div>
                                                            <img src="/image/dummy-profile.jpg" alt="" className="image" />
                                                        </div> 
                                                        
                                                    </div> */}
                                                    <div className=" userName user_div_section_column second_column " >
                                                        <p>{item?.email_id?.length >=25 ?item.email_id?.slice(0,25)+"...":item.email_id}</p>
                                                    </div>
                                                    
                                                    <div className=" user_div_section_column userDetail">
                                                            {/* <div style={{height: '13px', width: '13px',margin: '19px',background: 'green',borderRadius: '50%'}}></div> */}
                                                            <div>
                                                                <button type="button" className="btn details_button" onClick={() => userDetails(item.email_id)}>Details</button>
                                                            </div>
                                                    </div>
                                                </div>
                                        })}
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="col-6 card_section" id="card_section">  
                    <div className="admin_div">
                        <Card  className="update_div" id="userDetails">
                            <Card.Body>

                                    {/* change have done here */}
                                        {/* <div className="image_main_div" >
                                            <img src="/image/dummy-profile.jpg" style={{height: "8rem",borderRadius:"50%",width:"8rem",marginLeft:"42%",marginBottom:"5%"}}/> 
                                            <div className="image_div_section">
                                                <div className="image_div">
                                                    {userInfo?.filepreview == null ?<img src={image != null ? `data:image/png;base64,${image}` : '/image/dummy-profile.jpg'} className="previewimg" />:<img className="previewimg"  src={userInfo.filepreview} alt="UploadImage" />}
                                                    
                                                </div>
                                                <div className="button_section">
                                                    {!disableInput ? <div>
                                                        <div className="button_div" >
                                                            <button type="button" className="img_button" onClick={handleClick} >Add Image</button>
                                                            <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{display:'none'}} /> 
                                                        </div>
                                                        <div className="button_div">
                                                            <input type="button" className="img_button" onClick={submitImage} value="Submit" />
                                                        </div>
                                                    </div>:""}
                                                </div>
                                            </div>
                                                
                                        </div> */}
                                    
                                <div className="row form_section">
                                    <Card.Title className="card_title">
                                        <div className="row">
                                            <div className="col-lg-6 card_title_row">
                                                <h3>Profile</h3>
                                            </div>
                                            {/* {disableInput ?<div className="col-lg-6 card_title_row">
                                                    <button type="button" onClick={changeData} className="btn btn-success">
                                                    <FontAwesomeIcon icon={faPen} style={{color:"black",marginTop:"5%"}}/>
                                                    </button>
                                            </div>:""} */}
                                        </div>
                                    </Card.Title>
                                    <Card.Text className="user_details" >
                                        <Form className="col-lg-12">
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="2" md="3" id="titleName">
                                                First name
                                                </Form.Label>
                                                <Col sm="10" md="9">
                                                <Form.Control type="text" value={firstname} disabled={true} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="2"  md="3" id="titleName">
                                                Last name
                                                </Form.Label>
                                                <Col sm="10" md="9">
                                                <Form.Control type="text"  value={lastname} disabled={true} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="2" md="3" id="titleName">
                                                Email
                                                </Form.Label>
                                                <Col sm="10" md="9">
                                                <Form.Control type="email" value={email} disabled={true} onChange = {(e)=>{setEmail(e.target.value)}}/>
                                                </Col>
                                            </Form.Group>
                                            
                                            <Form.Group as={Row} className="mb-2 " controlId="formPlaintextPassword" >
                                                <Form.Label column sm="2" md="2" lg="3" id="titleName">
                                                User type
                                                </Form.Label>
                                                <Col sm="10" md="10" lg="9" className="userOptions">
                                                    <Col sm="1"  id="inputBox"><input type="radio" value="demo" checked={userType === "demo"} disabled={disableInput} onClick={() => setUserType("demo") }/></Col>
                                                    <Col sm="3">Demo</Col>
                                                    <Col sm="1"  id="inputBox"><input type="radio" value="full" checked={userType === "full"} disabled={disableInput} onClick={() => setUserType("full") }/></Col>
                                                    <Col sm="3">Paid</Col>
                                                    <Col sm="1"  id="inputBox"><input type="radio" value="Adm" checked={userType === "Adm"} disabled={disableInput} onClick={() => setUserType("Adm") }/></Col>
                                                    <Col sm="3">Admin</Col>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextPassword" >
                                                <Form.Label column sm="3" lg="3" id="titleName">
                                                    Active
                                                </Form.Label>
                                                <Col sm="9" lg="9" className="userOptions">
                                                    <Col sm="1" id="inputBox"><input type="radio" name="is_active" value="true" checked={isActive === true} disabled={disableInput} onClick={() => setIsActive(true) }/></Col>
                                                    <Col sm="3">True</Col>
                                                    <Col sm="1" id="inputBox"><input type="radio" name="is_active" value="false" checked={isActive === false} disabled={disableInput} onClick={() => setIsActive(false) }/></Col>
                                                    <Col sm="3">False</Col>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2" controlId="formPlaintextPassword" >
                                                <Form.Label column sm="5" lg="3" md="5" id="titleName">
                                                    Simultaneous login
                                                </Form.Label>
                                                <Col sm="7" lg="9" md="7" className="userOptions">
                                                    <Col sm="1" id="inputBox"><input type="radio" value="true" checked={syLogin === true} disabled={disableInput} name="simultaneous_flag" onClick={() => setSyLogin(true) }/></Col>
                                                    <Col sm="3">True</Col>
                                                    <Col sm="1" id="inputBox"><input type="radio" value="False" checked={syLogin === false} disabled={disableInput} name="simultaneous_flag" onClick={() => setSyLogin(false) }/></Col>
                                                    <Col sm="4">False</Col>
                                                </Col>
                                            </Form.Group>
                                                {/* <Form.Group as={Row} className="mb-2" controlId="formPlaintextPassword" >
                                                    <Form.Label column sm="4" lg="3">
                                                    User Source
                                                    </Form.Label>
                                                    <Col sm="8" lg="9">
                                                    <Form.Control type="text" value={resource} disabled={disableInput} onChange={(e)=>setResource(e.target.value)}/>
                                                    </Col>
                                                </Form.Group> */}

                                            {/* change have done here */}
                                            <Form.Group as={Row} className="mb-2 expiry" controlId="formPlaintextPassword" >
                                                    <Form.Label column sm="4" lg="3" id="titleName">
                                                    Expiry
                                                    </Form.Label>
                                                    <Col sm="8" lg="9">
                                                    <Calendar style={{width:"100%"}} id="basic" value={expiry} onChange={(e) => setExpiry(e.value)} showIcon  placeholder={expiry}/>
                                                    </Col>
                                                    <Col sm="6"></Col>
                                            </Form.Group>


                                            {/* //change have done here */}
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword" >
                                                <Form.Label column sm="4" lg="3" id="titleName">
                                                    Password
                                                </Form.Label>
                                                <Col sm="8" lg="9">
                                                <div className="passBtn">
                                                    <Form.Control type="text" value={pass} disabled={!showSaveBtn} onChange={(e)=>setPass(e.target.value)}/>
                                                    {showSubmitBtn && <div>
                                                        {!showSaveBtn && <Button variant="secondary" disabled={disableInput} style={{minWidth:"10%", marginLeft:"0.5rem"}} onClick={()=>{setShowPassChange(true)
                                                        setModalShow(true)}}>Change</Button>
                                                        }</div>
                                                    }
                                                </div>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3 " controlId="formPlaintextPassword" >
                                                <Form.Label column sm="3" lg="3" id="titleName">
                                                    Privilege
                                                </Form.Label>
                                                <Col sm="8" lg="9" style={{width:"65% !important", position:"relative"}}>
                                                    <div style={{display:"flex", flexDirection:"row"}}>
                                                        <div style={{display:"flex", flexDirection:"row", position:"relative", width:"100%"}}>
                                                            <Form.Control value={text} disabled={disableInput} type="text" placeholder="Search products" onChange={(e:any)=>handleSearch(e.target.value)} />
                                                            <img src="/image/search.png" alt="addBtn" style={{width:"1.5em",height:"1.5em", cursor:"pointer", position:"absolute", right:"10px", top:"6px"}} />
                                                        </div>
                                                        {showSubmitBtn && <div style={{display:"flex", flexDirection:"row"}}>
                                                            <Button variant="secondary" disabled={disableInput} style={{minWidth:"5rem", marginLeft:"0.2rem"}} onClick={()=>{setModalShow(true)
                                                            setShowPassChange(false)}} >Add all</Button>
                                                            <Button variant="secondary" disabled={disableInput} style={{minWidth:"7rem", marginLeft:"0.2rem"}} onClick={()=>{setModalShow(true)
                                                            setRemove(true)
                                                            setShowPassChange(false)}} >Remove all</Button>
                                                            </div>}
                                                    </div>
                                                    {/* <i className="fa-duotone fa-magnifying-glass"></i> */}
                                                    {showSubmitBtn && <Col className="card" id='codeDiv' style={{width:"100%", marginTop:"0.5rem", overflowY:"auto", paddingLeft:"0px", paddingRight:"0px", display:"flex",
                                                        flexDirection:"row", flexWrap:"wrap"}}>
                                                            
                                                        {privilegeCode.filter((val:any)=>{ return val.toLowerCase().indexOf(text.toLowerCase())> -1})
                                                        .map((item:any, index:any)=>{
                                                            return(<div className={`${userPrivilegeArr.includes(item)?"priChips gelatine":"priOutline"}`} key={item}>
                                                                 <p id="codeItem" style={{marginBottom: "0rem", width:"80%", marginLeft:"10%"}} >{item}</p>
                                                                { showSubmitBtn && <span style={{width:"20%"}} className={`${userPrivilegeArr.includes(item)?"closeBtn1":"imgBtn1"}`} >{userPrivilegeArr.includes(item)?
                                                                    <img src="/image/removeBTN.png" alt="addBtn" style={{width:"1.5em",height:"1.5em", cursor:"pointer"}} onClick={()=>{handleDeletePrivilege(item)}}/>:
                                                                    <img src="/image/addBTN.png" alt="addBtn" style={{width:"1.8em",height:"1.8em", cursor:"pointer"}} onClick={()=>{handleAddPrivilege(item)}}/>}</span>}
                                                            </div>)
                                                        })}
                                                    </Col>}
                                                </Col>
                                            </Form.Group>
                                                {showSubmitBtn ?<Form.Group  className="submitDiv1" controlId="formPlaintextPassword">
                                                    <button type="button" disabled={disableInput} onClick={updateForm} className="btn btn-success" style={{width:"50%"}}>Submit</button>
                                                    <button type="button" disabled={disableInput} style={{marginLeft:"10px", width:"50%"}} onClick={cancelUpdate} className="btn btn-secondary" >Cancel</button>
                                            </Form.Group> : ""} 
                                        </Form>                                                  
                                    </Card.Text>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            
            
           <MyVerticallyCenteredModal
                showAddBtn={showAddBtn}
                setModalShow={setModalShow}
                setShowAddBtn={setShowAddBtn}
                changeSymbol={changeSymbol}
                show={modalShow}
                onHide={() => {setModalShow(false)
                    setChangeSymbol("")}}
                setChangeSymbol = {setChangeSymbol}
                setShowSaveBtn={setShowSaveBtn}
                showPassChange = {showPassChange}
                setShowPassChange = {setShowPassChange}
                onAddAllPrivileges = {onAddAllPrivileges}
                onRemoveAllPrivilege={onRemoveAllPrivilege}
                setRemove={setRemove}
                remove = {remove}
            />
    </Container>
}


