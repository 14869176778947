import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useHistory } from "react-router";
import '../App.css';
import { AdminSidebar } from "./sidebar";

// interface data{
//     adminName:any
// }

export const AdminNavbar:React.FC = () =>{

    const history = useHistory();
    const signOut = () =>{
        sessionStorage.removeItem("token");
        history.push("/pages/login")
    }
    
    return(
        <Navbar bg="primary" expand="md" className="navbar" id="navBar" fixed="top" style={{marginLeft:"12px",marginRight:"12px"}}>
            <Container fluid >
                <div className="navContainer">
                    <input className="checkBox" type="checkbox" name="" id="" />
                    <div className="hamburger-lines">
                        <span className="line line1"></span>
                        <span className="line line2"></span>
                        <span className="line line3"></span>
                    </div>
                    <Navbar.Brand href="#" className="logo_section">Feedsense Admin</Navbar.Brand>
                    <div className="menu-items">
                        <AdminSidebar/>
                    </div>
                </div>
                <Nav className="justify-content-end nav">
                    <Nav.Link className="nav_link" href="#deets">
                        <button id="signOutBtnNAV" type="button" className="btn btn-danger" onClick={signOut}>SignOut</button>
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}

