import {axiosBaseUrl, axiosUrlAdvice} from '../service/axios'


export async function fetchAllPrivilegeCode(){
    let code:any=[];
    return await axiosBaseUrl.post(`user/all_privilegeCode`)
}

export async function getUserAllPrivilege(email:any){
    return await axiosBaseUrl.post(`/user/user_privilege`,{"username":email})
}

export async function deleteUserPrivilege(val:any, email:any){
    return await axiosBaseUrl.post(`user/delete_privilege`,{'value':val, 'user': email})
}

export async function addPrivilegeToUser(value:any, email:any){
    return await axiosBaseUrl.post(`/user/add_privilege`, {"email_id":email,"privilege":value})
}

export async function addAllPrivileges(email:any){
    return await axiosBaseUrl.post(`user/add_all_privileges`,{'email':email});
}

//fetch advices
export async function getAllAdvices(email:any, date:any){
    return axiosUrlAdvice.post(`/liveOverall/overall_live_advice_status`,{"username":email, "selectedDate": date})
}

//close Trade
export async function setTradeClose(e:any, username:any){
    console.log("e->",e)
    return axiosUrlAdvice.post(`/liveOverall/close_trade`,{"info":e, "username": username})
}


//fetch Symbol
export function getAllSymbol(){
    return axiosBaseUrl.post(`/lot_investment/get_all_symbol`)
    .then((res:any) => {
        return res?.data;
    }).catch((err:any) => console.log(err))
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//-------------------------------------------------------: INVESTMENT DETAILS START :--------------------------------------------------------------------------
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Fetch Investment
export function getInvestmentAndLot(){
    return axiosBaseUrl.post(`/lot_investment/get_investment_lot_details`)
    .then((res:any) => {
        return res?.data
    }).catch((err:any) => console.log(err))
}

//Insert Investment
export function getInsertInvestment(item:any){
    return axiosBaseUrl.post(`/lot_investment/get_insert_investment_details`,
    {
        "s_symbol" :  item[0]?.s_symbol,
        "n_investment" :  item[0]?.n_investment,
        "s_instrument" :  item[0]?.s_instrument,
        "s_month" :  item[0]?.s_month,
        "s_year" :  item[0]?.s_year,
        "dt_contract_start_date" :  item[0]?.dt_contract_start_date,
        "dt_contract_end_date" :   item[0]?.dt_contract_end_date
    }
    )
    .then((res:any) =>{
        return true
    }).catch((err:any) => {
        return false
    })
}


//Update Investment
export function getUpdateInvestmentLot(item:any){
    return axiosBaseUrl.post(`/lot_investment/get_update_investment`,
    {
        "s_symbol" :  item?.s_symbol,
        "n_investment" :  item?.n_investment,
        "s_instrument" :  item?.s_instrument,
        "s_month" :  item?.s_month,
        "s_year" :  item?.s_year,
        "dt_contract_start_date" :  item?.dt_contract_start_date,
        "dt_contract_end_date" :   item?.dt_contract_end_date,
        "n_id" : item?.n_id
    })
    .then((res:any) => {
        return res.data;
    }).catch((err:any) => console.log(err))
}



//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//------------------------------------------------------- : INVESTMENT DETAILS END :--------------------------------------------------------------------------
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//------------------------------------------------------- : LOT SIZE START :--------------------------------------------------------------------------
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//fetch all lot 
export function getLot(){
    return axiosBaseUrl.post(`/lot_investment/get_lot_details`)
    .then((res:any) => {
        return res?.data
    }).catch((err:any) => console.log(err))
}



//Insert Lot Size
export function getInsertLotsize(item:any){
    return axiosBaseUrl.post(`/lot_investment/get_insert_lotsize`,
    {
        "s_symbol" :  item[0]?.s_symbol,
        "n_lot_size" :  item[0]?.n_lot_size,
        "dt_start_date" :  item[0]?.dt_start_date,
        "dt_end_date" :  item[0]?.dt_end_date,
        "s_month" : item[0]?.s_month,
        "s_year" : item[0]?.s_year,
    }
    )
    .then((res:any) =>{
        return true
    }).catch((err:any) => {
        console.log(err)
        return false
    })
}


//update Lot size
export function getLotUpdate(item:any){
    return axiosBaseUrl.post(`/lot_investment/get_lot_update`,
    {
        "s_symbol" :  item?.s_symbol,
        "n_lot_size" : item?.n_lot_size,
        "dt_start_date" :  item?.dt_start_date,
        "dt_end_date" :   item?.dt_end_date,
        "s_month" : item?.s_month,
        "s_year" : item?.s_year,
        "n_id" : item?.n_id
    }
    )
    .then((res:any) => {
        return res.data;
    }).catch((err:any) => console.log("UPDATE ERROR : ",err))
}



//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//------------------------------------------------------- : LOT SIZE END :--------------------------------------------------------------------------
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//fetch Instrument according to symbol
export function getInstrument(s_symbol:any){
    return axiosBaseUrl.post('/lot_investment/get_instrument',{
        "s_symbol" : s_symbol
    })
    .then((res:any) =>{
        return res?.data
    }).catch((err:any) =>console.log(err))
}