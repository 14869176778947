import React, { useEffect, useRef, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { AdminNavbar } from './navbar';
import { AdminSidebar } from './sidebar';
import { Toast } from 'primereact/toast';
import '../pages/css/advice.css'
import { getAllAdvices, setTradeClose } from './apiCalls';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { axiosBaseUrl } from "../service/axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function MyVerticallyCenteredModal(props:any) {
    const [passwordIcon ,setPasswordIcon] = useState<any>(faEye); 
    const showPassword = () =>{
        let x:any = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
            setPasswordIcon(faEyeSlash)
        } else {
            x.type = "password";
            setPasswordIcon(faEye)
        }
    }
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName='adviceModal'
      >
        <Modal.Body ><>
            <div  style={{display:"flex", flexDirection:"column", width:"100%"}} >
                <input type="text" autoComplete='email' id='email' className='form-control sm-8' placeholder="Enter your email" style={{marginBottom:"1rem"}} onChange={(e:any)=>props.setCurEmail(e?.target?.value)}></input>
                <div style={{width:"100%", position:"relative", marginRight:"2rem"}}>
                    <input type="password" autoComplete='password1' id='password' className='form-control sm-8' placeholder="Enter your password" style={{marginBottom:"1rem"}} onChange={(e:any)=>props.setPassword(e?.target?.value)}></input>
                    <FontAwesomeIcon icon={passwordIcon} style={{position:"absolute",top:"10px",right:"8%"}} onClick={()=>showPassword()}/>
                </div>
                
                <button className='btn btn-secondary' onClick={()=>{props.handleSubmit()}}>Close the trade</button>    
            </div>
        </>
        </Modal.Body>
      </Modal>
    );
  }

export const Advice:React.FC = ()=>{

    const toast = useRef<any>(null);

    const[advices, setAdvices] =  useState<any>([]);
    const [email,setEmail] = useState<any>(sessionStorage.getItem("username"))//vista@intelligence.ai2
    const [btnClicked, setBtnClicked] = useState<boolean>(false);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [agreed, setAgree] = useState<any>(false);
    const [password, setPassword] = useState<any>('');
    const [selectedTrade, setSelectedTrade] = useState<any>('');
    const [curEmail, setCurEmail] = useState<any>('');
    const [currentTime, setCurrentTime] = useState<any>(new Date().toLocaleTimeString())
    const [clicked, setClicked] = useState<boolean>(false);
    const [advicesStatusType, setAdvicesStatusType] = useState<any>("derivative")
    const [mainAdvices, setMainAdvices] = useState<any>([])

    let noOfCalls = advices?.length;
    useEffect(()=>{
        let advices_filter_arr:any = []
        advices_filter_arr = mainAdvices?.filter((item:any) =>{
            if(advicesStatusType == 'derivative'){
                return (item?.s_version).slice(1,3) !== 'ES' &&  item?.s_version != 'AIODDI'
            }else if(advicesStatusType == 'hft'){
                return item?.s_version == 'AIODDI'
            }else{
                return (item?.s_version).slice(1,3) === 'ES'
            }
        })
        setAdvices(advices_filter_arr)
    },[advicesStatusType, mainAdvices])
    const showSuccess = (val:any) => {
        toast.current.show({severity:'success', summary: 'Success', detail:`${val}`, life: 3000});
    }
    const showError = (val:any) => {
        toast.current.show({severity:'error', summary: 'Error', detail:`${val}`, life: 3000});
    }

    const handleAdviceCall = async()=>{
        let date:any = moment(new Date()).format("YYYY-MM-DD")
        await getAllAdvices(email,date)
        .then((res:any)=>{
            setMainAdvices(res?.data.final_response)
        }).catch((e:any)=>console.log(e))
    }

    useEffect(()=>{
        handleAdviceCall();
    },[btnClicked])

    useEffect(()=>{
        let interval:any = setInterval(()=>{
            let current_time = new Date().toLocaleTimeString()
            setCurrentTime(current_time)
            handleAdviceCall();
        },1*60*1000)
        return () => {
            clearInterval(interval);
        }
    },[])

    const handleCloseTrade = (value:any)=>{
        setModalShow(true);
        setSelectedTrade(value);
    }

    const handleCloseTradeAPICALL = async(data:any)=>{
        await setTradeClose(selectedTrade, email)
        .then((res:any)=>{
            if(res?.data === true){
                showSuccess('Close successfully');
                setModalShow(false) ;
                setAgree(false);
            }else{showError('Error')}
            setBtnClicked(!btnClicked);
        })
        .catch((e:any)=>showError('Error'))
    }

    const handleSubmit = async(e:any)=>{
        // e.preventDefault();
        await axiosBaseUrl.post(`/user/match_password`,{pass:password, email: curEmail})
        .then((res:any)=>{
            if(res?.data?.status === "success"){
                handleCloseTradeAPICALL(res?.data);
            }else{
                showError('Incorrect password')
            }
        }).catch((e:any)=>showError('Incorrect password'))
    }

    const handleRefresh = () =>{
        handleAdviceCall();
        setClicked(true);
        let current_time = new Date().toLocaleTimeString()
        setCurrentTime(current_time)
        setTimeout(()=>{	
            setClicked(false)	
        },1000)
    }
    
    return(
        <Container fluid>
            <Toast ref={toast}/>
            <div >
                <AdminNavbar/>
            </div>
            <div className="row main" >
                <div className="col-2 sideBar" >
                    <AdminSidebar/>
                </div>
                <div className="col-10" id="adviceCard" style={{height:"85vh"}}>
                    <div style={{display:"flex", justifyContent: "space-between", margin:"0.5rem 2rem 1rem 0rem"}}>
                        <div style={{display:"flex", flexDirection:"row", marginTop:"2rem"}}>
                            <button className={advicesStatusType=='derivative'?' btn active':'btn btnDerivative'} style={{marginRight:"1rem" }} onClick={() => { setAdvicesStatusType("derivative") }}>F&O</button> 
                            <button className={advicesStatusType=='spot'?' btn active':'btn btnDerivative'} style={{marginRight:"1rem" }} onClick={() => { setAdvicesStatusType("spot") }}>Equity</button>
                            <button className={advicesStatusType=='hft'?' btn active':'btn btnDerivative'} onClick={() => { setAdvicesStatusType("hft") }}>LFT</button>                            <div className="calls">
                               <span>Calls: {noOfCalls}</span>
                            </div>
                        </div>
                        <div style={{display:"flex", justifyContent: "end", width:'40%', marginTop:"1.5rem"}}>
                            <div className='refresh-para' >Last refreshed on {currentTime}</div>
                            <button className='refresh-button'  onClick={() => handleRefresh()}><i className={clicked?'rotating pi pi-refresh refresh-icon':'pi pi-refresh refresh-icon' } ></i></button> 
                        </div>
                    </div>
                    <div className="advice_div" >
                        <Card  className="advice_card" >
                            <div className="adviceTable-div" style={{ overflowY: "auto", height:"100%" ,overflowX: "auto"}}>
                                <table className="table table-hover">
                                    <thead className="thead-dark t_head" style={{position:"sticky", top: "0", borderTop:"2px solid black"}}>
                                        <tr>
                                            <th style={{minWidth:"12rem", textAlign:"center"}}>Close Reason</th>
                                            <th style={{minWidth:"10rem", textAlign:"center"}}>Symbol</th>
                                            <th style={{minWidth:"7rem", textAlign:"center"}}>Code</th>
                                            <th style={{minWidth:"10rem", textAlign:"center"}}>Open Date</th>
                                            <th style={{minWidth:"7rem", textAlign:"center"}}>P/L</th>
                                            <th style={{minWidth:"10rem", textAlign:"center"}}>Transaction Price</th>
                                            <th style={{minWidth:"8rem", textAlign:"center"}}>Stoploss</th>
                                            <th style={{minWidth:"8rem", textAlign:"center"}}>Target</th>
                                            <th style={{minWidth:"7rem", textAlign:"center"}}>P/L(rs)</th>
                                            <th style={{minWidth:"10rem", textAlign:"center"}}>Close Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {advices.map((item: any) => {
                                            return (<tr className={item.s_reason=='Manual'?"addActive":'tabl'} style={{height:"15%"}}>
                                                        <td style={{textAlign:"center"}}>{item.s_reason === null || item.s_reason === '' ? <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={(e) => {
                                                                    handleCloseTrade(item);
                                                                }}
                                                            >Close forcefully</button>:
                                                            <span  >{item.s_reason}</span>}
                                                        </td>
                                                        <td style={{textAlign:"center"}}>{item.asset}</td>
                                                        <td style={{textAlign:"center"}}>{item.identifier}</td>
                                                        <td style={{textAlign:"center"}}>{item.open_date}</td>
                                                        <td style={{textAlign:"center"}}>{item.asset === 'USDINR-I.CDS'?Number(item.trade_pl).toFixed(4):Number(item.trade_pl).toFixed(2)}</td>
                                                        <td style={{textAlign:"center"}}>{item.asset === 'USDINR-I.CDS'?Number(item.n_open_transaction_price).toFixed(4):Number(item.n_open_transaction_price).toFixed(2)}</td>
                                                        <td style={{textAlign:"center"}}>{item.asset === 'USDINR-I.CDS'?Number(item.n_stoploss).toFixed(4):Number(item.n_stoploss).toFixed(2)}</td>
                                                        <td style={{textAlign:"center"}}>{item.asset === 'USDINR-I.CDS'?Number(item.n_target).toFixed(4):Number(item.n_target).toFixed(2)}</td>
                                                        <td style={{textAlign:"center"}}>{item.asset === 'USDINR-I.CDS'?Number(item.trade_pl_rs).toFixed(4):Number(item.trade_pl_rs).toFixed(2)}</td>
                                                        <td style={{textAlign:"center"}}>{item.trade_close_date}</td>
                                                    </tr>)
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <MyVerticallyCenteredModal
                setModalShow={setModalShow}
                show={modalShow}
                onHide={() => {setModalShow(false)
                setAgree(false)}}
                handleSubmit={handleSubmit}
                setPassword={setPassword}
                setCurEmail={setCurEmail}
            />
        </Container>
    )
}