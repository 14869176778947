import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Redirect, Route, BrowserRouter as Router, Switch, } from 'react-router-dom';
import './App.css';
import { AdminPanel } from './pages/admin';
import { CreateUser } from './pages/createUser';
import { Investment } from './pages/investment';
import { LoginProtal } from './pages/login';
import { Lot } from './pages/lot';
import { Advice } from './pages/advice';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';

// import { AdminPanel } from './pages/admin';


function isLoggedin() {
  let token = sessionStorage.getItem("token");
  // let res: any = await axios.get(`http://localhost:4000/user/verify_token?token=${token}`)
  // if(res.data.status === "success") {
  //   return true
  // }
  if(token && token !== null) {
    return true;
  }
  return false
}

function PrivateRoute ({ children, ...rest }: any) {
  let loggedIn = isLoggedin()

  return (
    <Route {...rest} render={() => {
      return loggedIn ? children : <Redirect to='/pages/login' />
    }} />
  )
}

function App() {
  return (
    <div className="App">
      
        <Router>
          
          <Route path="/pages/login">
            <LoginProtal/>
          </Route>
          <PrivateRoute exact path="/pages/create-user">
            <CreateUser/>
          </PrivateRoute>
          <PrivateRoute exact path="/pages/admin_panel">
            <AdminPanel/>
          </PrivateRoute>
          <PrivateRoute exact path="/pages/investment_panel">
            <Investment/>
          </PrivateRoute>
          <PrivateRoute exact path="/pages/lot_panel">
            <Lot/>
          </PrivateRoute>
          <PrivateRoute exact path="/pages/advice_panel">
            <Advice/>
          </PrivateRoute>
          <Route exact path="/">
            <Redirect to='/pages/login' />
          </Route>
        </Router>
    </div>
  );
}

export default App;
