import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
 import '../App.css';

export const AdminSidebar:React.FC = () =>{


    const [selected,setSelected] = useState<any>()


  // $("#menu-toggle").click(function(e) {
  //   e.preventDefault();
  //   $("#wrapper").toggleClass("toggled");
  // });
  const history = useHistory();
//   const pathname = window.location.pathname;
//   console.log(pathname);
//   if(pathname == "/pages/admin_panel")
//   {
//     let homemenu:any = document.getElementById("homemenu")
//     homemenu.className("active")
//   }
//   else if(pathname == "/pages/admin_panel")
//   {
//     let addusermenu:any = document.getElementById("addusermenu")
//     addusermenu.className("active")
//   }
const signOut = () =>{
    sessionStorage.removeItem("token");
    history.push("/pages/login")
}
  
  
    return <div id="wrapper">
            <div id="sidebar-wrapper">
              <ul className="sidebar-nav">
                  <li className={window.location.pathname === "/pages/admin_panel" ? "active" : ""}>
                      <a href="/pages/admin_panel">All User</a>
                  </li>
                  <li className={window.location.pathname === "/pages/create-user" ? "active" : ""}>
                      <a href="/pages/create-user">Add User</a>
                  </li>
                  <li className={window.location.pathname === "/pages/investment_panel" ? "active" : ""}>
                      <a href="/pages/investment_panel">Investment input</a>
                  </li>
                  <li className={window.location.pathname === "/pages/lot_panel" ? "active" : ""}>
                      <a href="/pages/lot_panel">Lot input</a>
                  </li>
                  <li className={window.location.pathname === "/pages/advice_panel" ? "active" : ""}>
                      <a href="/pages/advice_panel">Trade close</a>
                  </li>
                  <li id="signOutBtn" style={{marginTop:"100%", marginLeft:"25%"}}>
                    <button type="button" style={{width:"12rem"}} className="btn btn-danger" onClick={signOut}>SignOut</button>
                  </li>
              </ul>
          </div>
    </div>
    
    
    
  //   <div className="sidebar">
  //   <a href="/pages/admin">Home</a>
  //   <a href="/pages/create-user">Create</a>
    
  // </div>

}